@import "colours.scss";


.context {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.unlockable-card {
	$default-border       : url('./images/unlockable_card/templates/border_card.png') 50 / 50px / 25px round;

	margin                : 30px; // Minimum of 25px based on max border sizes
	padding               : 0;
	display               : flex;
	flex-direction        : column;
	min-width             : 200px;
	max-width             : 200px;
	min-height            : 360px;
	max-height            : 360px;
	transform             : perspective(1000px);
	transform-style       : preserve-3d;
	background-color      : white;
	box-shadow            : rgba(20, 26, 40, 0.4) 0px 7px 42px;
	border-image          : $default-border;
	image-rendering       : pixelated;

	&::after,
	&::before {
		content               : "";
		position              : absolute;
		top                   : 0;
		right                 : 0;
		left                  : 0;
		bottom                : 0;
		border-image          : $default-border;
	}

	&::before {
		transform             : translateZ(-5px);
	}

	&::after {
		pointer-events        : none;
		transform             : translateZ(5px);
	}

	.rarity-badge {
		position              : relative;
		min-width             : 24px;
		max-width             : 24px;
		min-height            : 24px;
		max-height            : 24px;
		background-size       : cover;
		background-color      : $c--rarity-1;
		background-blend-mode : multiply;
		background-image      : url('./images/unlockable_card/rarity_sphere.png');
		mask                  : url('./images/unlockable_card/rarity_sphere.png');
		margin-right          : 5px;

		&::after {
			content               : "";
			position              : absolute;
			top                   : 0;
			right                 : 0;
			left                  : 0;
			bottom                : 0;
			background            : url('./images/unlockable_card/rarity_1/icon.png') center no-repeat;
		}
	}

	& &-header,
	& &-contents {
		display               : flex;
		padding               : 10px;
		box-sizing            : border-box;
		background-color      : rgba(0, 0, 0, 0.03);
		transform             : translateZ(5px);
	}

	& &-header {
		align-items           : center;
		border-bottom         : 1px solid rgba(0, 0, 0, 0.125);
	}

	& &-contents {
		flex-direction        : column;
		border-top            : 1px solid rgba(0, 0, 0, 0.125);
		flex                  : 1 1 0;
	}

	& &-title {
		margin                : 0;
		letter-spacing        : -0.5px;
		font-family           : 'VT323', monospace;
	}

	& &-image-container {
		margin                : 10px;
		border-image          : url('./images/unlockable_card/templates/border_image.png') 10 / 10px / 10px round;
	}

	& &-image {
		width                 : 100%;
		padding-top           : 80%;
		background-position   : center;
		background-size       : cover;
		background-repeat     : no-repeat;
		image-rendering       : optimizeQuality;
	}

	& &-details {
		padding-top           : 5px;
		display               : flex;
		align-items           : center;
		font-size             : 50%;
		font-family           : 'Quantico', sans-serif;
	}

	& &-description {
		font-size             : 55%;
		text-align            : justify;
		border-bottom         : 2px solid black;
		padding-bottom        : 5px;
		margin                : 0;
		font-family           : 'Quantico', sans-serif;
		flex                  : 1 0 0;
	}

	// Common

	&.common  {
		border-image-source     : url('./images/unlockable_card/rarity_1/border_stand.png');

		&::before {
			border-image-source : url('./images/unlockable_card/rarity_1/border_lower.png');
		}
		&::after {
			border-image-source : url('./images/unlockable_card/rarity_1/border_upper.png');
		}
		.unlockable-card-image-container {
			border-image-source : url('./images/unlockable_card/rarity_1/border_image.png');
		}

		.rarity-badge {
			background-color : $c--rarity-1;

			&::after {
				background-image : url('./images/unlockable_card/rarity_1/icon.png');
			}
		}

		// NOTE: Special attributes for "Common" rarity

		border-radius       : 8px;

		.unlockable-card-header {
			border-top-left-radius  : 8px;
			border-top-right-radius : 8px;
		}

		.unlockable-card-contents {
			border-bottom-left-radius  : 8px;
			border-bottom-right-radius : 8px;
		}
	}

	// Uncommon

	&.uncommon {
		border-image-source     : url('./images/unlockable_card/rarity_2/border_stand.png');

		&::before {
			border-image-source : url('./images/unlockable_card/rarity_2/border_lower.png');
		}
		&::after {
			border-image-source : url('./images/unlockable_card/rarity_2/border_upper.png');
		}
		.unlockable-card-image-container {
			border-image-source : url('./images/unlockable_card/rarity_2/border_image.png');
		}

		.rarity-badge {
			background-color : $c--rarity-2;

			&::after {
				background-image : url('./images/unlockable_card/rarity_2/icon.png');
			}
		}
	}

	// Rare

	&.rare {
		border-image-source     : url('./images/unlockable_card/rarity_3/border_stand.png');

		&::before {
			border-image-source : url('./images/unlockable_card/rarity_3/border_lower.png');
		}
		&::after {
			border-image-source : url('./images/unlockable_card/rarity_3/border_upper.png');
		}
		.unlockable-card-image-container {
			border-image-source : url('./images/unlockable_card/rarity_3/border_image.png');
		}

		.rarity-badge {
			background-color : $c--rarity-3;

			&::after {
				background-image : url('./images/unlockable_card/rarity_3/icon.png');
			}
		}
	}

	// Mythic

	&.mythic {
		border-image-source     : url('./images/unlockable_card/rarity_4/border_stand.png');

		&::before {
			border-image-source : url('./images/unlockable_card/rarity_4/border_lower.png');
		}
		&::after {
			border-image-source : url('./images/unlockable_card/rarity_4/border_upper.png');
		}
		.unlockable-card-image-container {
			border-image-source : url('./images/unlockable_card/rarity_4/border_image.png');
		}

		.rarity-badge {
			background-color : $c--rarity-4;

			&::after {
				background-image : url('./images/unlockable_card/rarity_4/icon.png');
			}
		}
	}

	// Legendary

	&.legendary {
		border-image-source : url('./images/unlockable_card/rarity_5/border_stand.png');

		&::before {
			border-image-source : url('./images/unlockable_card/rarity_5/border_lower.png');
		}
		&::after {
			border-image-source : url('./images/unlockable_card/rarity_5/border_upper.png');
		}
		.unlockable-card-image-container {
			border-image-source : url('./images/unlockable_card/rarity_5/border_image.png');
		}

		.rarity-badge {
			background-color : $c--rarity-5;

			&::after {
				background-image : url('./images/unlockable_card/rarity_5/icon.png');
			}
		}
	}

}
