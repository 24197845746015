@import "keyframes.scss";

.unlockable-chest {
	&-image {
		display: block;
		position: relative;
		visibility: visible;

		&.opening {
			animation:
				AnimateJiggle 0.1s linear infinite,
				AnimateSquish 0.5s ease-out both;
		}

		&.opened {
			animation: AnimateSquish 0.6s ease-in-out reverse both;
		}
	}

	&-container {
		&.opened {
			animation: AnimateFadeIn 1.0s linear 2.0s reverse both;
		}
	}

	&-key {
		margin: -0.9em 0 -0.9em 8px;
		height: 1.8em;
	}

	&-treasure {
		&.hidden {
			visibility: collapse;
		}

		&:not(.hidden) {
			animation:
				AnimatePopOut 0.5s linear 0.2s both,
				AnimateBounce 0.5s cubic-bezier(.25,.5,.75,.5) 0.2s both;
		}
	}
}

/* Keyframes */

@keyframes AnimateSquish {
	from {
		scale: 1.00 1.00;
		translate: 0 0 0;
	}
	to   {
		scale: 1.20 0.80;
		translate: 0 10% 0; /* Offset so the squished image doesn't look so funny */
	}
}
