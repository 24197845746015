.context {
  flex-flow: wrap;
  display: flex;
}

.unlockable-card {
  min-width: 200px;
  max-width: 200px;
  min-height: 360px;
  max-height: 360px;
  transform-style: preserve-3d;
  image-rendering: pixelated;
  background-color: #fff;
  border-image: url("border_card.bf2454f1.png") 50 / 50px / 25px round;
  flex-direction: column;
  margin: 30px;
  padding: 0;
  display: flex;
  transform: perspective(1000px);
  box-shadow: 0 7px 42px #141a2866;
}

.unlockable-card:after, .unlockable-card:before {
  content: "";
  border-image: url("border_card.bf2454f1.png") 50 / 50px / 25px round;
  position: absolute;
  inset: 0;
}

.unlockable-card:before {
  transform: translateZ(-5px);
}

.unlockable-card:after {
  pointer-events: none;
  transform: translateZ(5px);
}

.unlockable-card .rarity-badge {
  min-width: 24px;
  max-width: 24px;
  min-height: 24px;
  max-height: 24px;
  background-blend-mode: multiply;
  background-color: #6c757d;
  background-image: url("rarity_sphere.36f0d012.png");
  background-size: cover;
  margin-right: 5px;
  position: relative;
  mask: url("rarity_sphere.36f0d012.png");
}

.unlockable-card .rarity-badge:after {
  content: "";
  background: url("icon.d439856b.png") center no-repeat;
  position: absolute;
  inset: 0;
}

.unlockable-card .unlockable-card-header, .unlockable-card .unlockable-card-contents {
  box-sizing: border-box;
  background-color: #00000008;
  padding: 10px;
  display: flex;
  transform: translateZ(5px);
}

.unlockable-card .unlockable-card-header {
  border-bottom: 1px solid #00000020;
  align-items: center;
}

.unlockable-card .unlockable-card-contents {
  border-top: 1px solid #00000020;
  flex-direction: column;
  flex: 1;
}

.unlockable-card .unlockable-card-title {
  letter-spacing: -.5px;
  margin: 0;
  font-family: VT323, monospace;
}

.unlockable-card .unlockable-card-image-container {
  border-image: url("border_image.449f3a78.png") 10 / 10px / 10px round;
  margin: 10px;
}

.unlockable-card .unlockable-card-image {
  width: 100%;
  image-rendering: optimizequality;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 80%;
}

.unlockable-card .unlockable-card-details {
  align-items: center;
  padding-top: 5px;
  font-family: Quantico, sans-serif;
  font-size: 50%;
  display: flex;
}

.unlockable-card .unlockable-card-description {
  text-align: justify;
  border-bottom: 2px solid #000;
  flex: 1 0;
  margin: 0;
  padding-bottom: 5px;
  font-family: Quantico, sans-serif;
  font-size: 55%;
}

.unlockable-card.common {
  border-image-source: url("border_stand.b7bb1b24.png");
  border-radius: 8px;
}

.unlockable-card.common:before {
  border-image-source: url("border_lower.0b92a987.png");
}

.unlockable-card.common:after {
  border-image-source: url("border_upper.7fddbebe.png");
}

.unlockable-card.common .unlockable-card-image-container {
  border-image-source: url("border_image.bb293f08.png");
}

.unlockable-card.common .rarity-badge {
  background-color: #6c757d;
}

.unlockable-card.common .rarity-badge:after {
  background-image: url("icon.d439856b.png");
}

.unlockable-card.common .unlockable-card-header {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.unlockable-card.common .unlockable-card-contents {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.unlockable-card.uncommon {
  border-image-source: url("border_stand.1cc8e221.png");
}

.unlockable-card.uncommon:before {
  border-image-source: url("border_lower.1255417d.png");
}

.unlockable-card.uncommon:after {
  border-image-source: url("border_upper.faf2b7ed.png");
}

.unlockable-card.uncommon .unlockable-card-image-container {
  border-image-source: url("border_image.bb293f08.png");
}

.unlockable-card.uncommon .rarity-badge {
  background-color: #ffc107;
}

.unlockable-card.uncommon .rarity-badge:after {
  background-image: url("icon.c6c6b601.png");
}

.unlockable-card.rare {
  border-image-source: url("border_stand.0a33b242.png");
}

.unlockable-card.rare:before {
  border-image-source: url("border_lower.4506e0cf.png");
}

.unlockable-card.rare:after {
  border-image-source: url("border_upper.a7394092.png");
}

.unlockable-card.rare .unlockable-card-image-container {
  border-image-source: url("border_image.b683e515.png");
}

.unlockable-card.rare .rarity-badge {
  background-color: #dc3545;
}

.unlockable-card.rare .rarity-badge:after {
  background-image: url("icon.b33678a5.png");
}

.unlockable-card.mythic {
  border-image-source: url("border_stand.8a4a2855.png");
}

.unlockable-card.mythic:before {
  border-image-source: url("border_lower.70e10bed.png");
}

.unlockable-card.mythic:after {
  border-image-source: url("border_upper.6621110b.png");
}

.unlockable-card.mythic .unlockable-card-image-container {
  border-image-source: url("border_image.24e099af.png");
}

.unlockable-card.mythic .rarity-badge {
  background-color: #17a2b8;
}

.unlockable-card.mythic .rarity-badge:after {
  background-image: url("icon.594a4e49.png");
}

.unlockable-card.legendary {
  border-image-source: url("border_stand.d61c0d78.png");
}

.unlockable-card.legendary:before {
  border-image-source: url("border_lower.c9a1551b.png");
}

.unlockable-card.legendary:after {
  border-image-source: url("border_upper.b1ecf35b.png");
}

.unlockable-card.legendary .unlockable-card-image-container {
  border-image-source: url("border_image.91d5bddf.png");
}

.unlockable-card.legendary .rarity-badge {
  background-color: #f8f9fa;
}

.unlockable-card.legendary .rarity-badge:after {
  background-image: url("icon.3e8348bc.png");
}

@keyframes AnimatePopOut {
  from {
    scale: 0;
  }

  to {
    scale: 1;
  }
}

@keyframes AnimateFadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes AnimateJiggle {
  0% {
    rotate: 0;
  }

  25% {
    rotate: 2.5deg;
  }

  50% {
    rotate: 0;
  }

  75% {
    rotate: -2.5deg;
  }

  100% {
    rotate: 0;
  }
}

@keyframes AnimateBounce {
  0% {
    translate: 0;
  }

  50% {
    translate: 0 -25%;
  }

  100% {
    translate: 0;
  }
}

.unlockable-chest-image {
  visibility: visible;
  display: block;
  position: relative;
}

.unlockable-chest-image.opening {
  animation: AnimateJiggle .1s linear infinite, AnimateSquish .5s ease-out both;
}

.unlockable-chest-image.opened {
  animation: AnimateSquish .6s ease-in-out reverse both;
}

.unlockable-chest-container.opened {
  animation: AnimateFadeIn 1s linear 2s reverse both;
}

.unlockable-chest-key {
  height: 1.8em;
  margin: -.9em 0 -.9em 8px;
}

.unlockable-chest-treasure.hidden {
  visibility: collapse;
}

.unlockable-chest-treasure:not(.hidden) {
  animation: AnimatePopOut .5s linear .2s both, AnimateBounce .5s cubic-bezier(.25, .5, .75, .5) .2s both;
}

@keyframes AnimateSquish {
  from {
    translate: 0;
    scale: 1;
  }

  to {
    translate: 0 10%;
    scale: 1.2 .8;
  }
}

/*# sourceMappingURL=index.aa0cfa51.css.map */
