@keyframes AnimatePopOut {
	from { scale: 0.0; }
	to   { scale: 1.0; }
}

@keyframes AnimateFadeIn {
	from { opacity: 0.0; }
	to   { opacity: 1.0; }
}

@keyframes AnimateJiggle {
	0%   { rotate: 0; }
	25%  { rotate:  2.5deg; }
	50%  { rotate: 0; }
	75%  { rotate: -2.5deg; }
	100% { rotate: 0; }
}

@keyframes AnimateBounce {
	0%   { translate: 0 0 0; }
	50%  { translate: 0 -25% 0; }
	100% { translate: 0 0 0; }
}
